/* eslint-disable react/no-children-prop */
import React from "react"
import styled from "styled-components/macro"
import { useStaticQuery, graphql } from "gatsby"

import RicettaCard from "../RicettaCard/RicettaCard"

import { QUERY, COLORS } from "../../constants"

const RicetteLista = () => {
  const data = useStaticQuery(graphql`
    query RicetteQuery {
      allStrapiRicette {
        edges {
          node {
            ingredienti
            procedimento
            titolo
            likes
            foto {
              alternativeText
              file {
                id
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
    }
  `)


  const listaRicette = data.allStrapiRicette.edges
  const ricette = listaRicette.map(
    ({ node: { id, titolo, ingredienti, procedimento, likes, foto } }) => (
      <RicettaCard
        key={id}
        titolo={titolo}
        ingredienti={ingredienti}
        procedimento={procedimento}
        likes={likes}
        foto={foto}
      />
    )
  )

  return (
    <>
      <MainTitle>Ricette</MainTitle>
      <ListaRicette>{ricette}</ListaRicette>
    </>
  )
}

const MainTitle = styled.h1`
  margin-top: 4rem;
  color: ${COLORS.red};
  padding: 2rem 4.5rem;
  margin-bottom: 2rem;
  font-size: calc(3.2 * var(--baseline-size));
  font-weight: 100;
  text-transform: uppercase;

  @media ${QUERY.medium} {
    font-size: calc(2.2 * var(--baseline-size));
  }

  @media ${QUERY.small} {
    font-size: calc(1.8 * var(--baseline-size));
    margin-bottom: 0;
  }

  @media ${QUERY.small} {
    padding: 2.5rem;
  }

  @media ${QUERY.smaller} {
    padding: 1.5rem;
  }

  @media ${QUERY.smallest} {
    padding: 1rem;
  }
`

const ListaRicette = styled.section`
  padding: 4rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  background: ${COLORS.lightBlue};
 
  @media ${QUERY.mediumSmall} {
    padding: 2rem;
 
  }

  @media ${QUERY.smaller} {
    padding: 1rem;

  }

  @media ${QUERY.smallest} {
    padding: 0.5rem;
  }
`

export default RicetteLista
